import Vue from 'vue';
import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';
import wootConstants from 'dashboard/constants/globals';

const state = {
  openCount: 0,
  allCount: 0,
  pendingCount: 0,
  closedCount: 0,
  allTabCounts: {
    openCount: 0,
    pendingCount: 0,
    closedCount: 0,
  },
  snoozedCounts: {
    openCount: 0,
    pendingCount: 0,
  },
};

export const getters = {
  getStats: $state => $state,
};

export const actions = {
  get: async ({ commit }, params) => {
    try {
      const response = await ConversationApi.meta(params);
      const {
        data: { meta },
      } = response;

      const commitPayload = {
        meta,
        relatedStatus: params.status,
        relatedType: params.conversationType,
      };
      commit(types.SET_CONV_TAB_META, commitPayload);
    } catch (error) {
      // Ignore error
    }
  },
  set({ commit }, payload) {
    commit(types.SET_CONV_TAB_META, payload);
  },
  resetClosed({ commit }) {
    const payload = {
      meta: { closed_count: 0, all_count: 0 },
      relatedStatus: wootConstants.STATUS_TYPE.RESOLVED,
      relatedType: 'all',
    };

    commit(types.SET_CONV_TAB_META, payload);
  },
};

export const mutations = {
  [types.SET_CONV_TAB_META]($state, payload) {
    const {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
      open_count: openCount,
      closed_count: closedCount,
    } = payload.meta;
    const { relatedStatus, relatedType } = payload;

    switch (relatedStatus) {
      case wootConstants.STATUS_TYPE.OPEN:
        Vue.set($state, 'openCount', openCount);
        Vue.set($state, 'allCount', allCount);
        break;
      case wootConstants.STATUS_TYPE.PENDING:
        Vue.set($state, 'pendingCount', allCount);
        break;
      case wootConstants.STATUS_TYPE.RESOLVED:
        Vue.set($state, 'closedCount', closedCount);
        break;
      case wootConstants.STATUS_TYPE.SNOOZED:
        Vue.set($state.snoozedCounts, 'openCount', mineCount);
        Vue.set($state.snoozedCounts, 'pendingCount', unAssignedCount);
        break;
      default:
    }

    if (relatedType === 'all') {
      switch (relatedStatus) {
        case wootConstants.STATUS_TYPE.OPEN:
          Vue.set($state.allTabCounts, 'openCount', allCount);
          break;
        case wootConstants.STATUS_TYPE.PENDING:
          Vue.set($state.allTabCounts, 'pendingCount', allCount);
          break;
        case wootConstants.STATUS_TYPE.RESOLVED:
          Vue.set($state.allTabCounts, 'closedCount', allCount);
          break;
        default:
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
